<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Attendance</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #page-content>
      <b-modal v-model="isComponentModalActive" full-screen can-cancel>
        <ActivityDetail
          v-if="selectedAttendance"
          :user-id="selectedUserId"
          :user-name="selectedUserName"
          :date="selectedAttendance.date"
        />
      </b-modal>
      <div id="employee-attendance">
        <div class="columns is-multiline p-0 pb-2 m-0 is-align-items-center">
          <div class="column is-6 p-0">
            <div class="is-flex is-align-items-center">
              <div class="date-picker-container is-align-items-center is-flex">
                <b-datepicker
                  :mobile-native="false"
                  v-model="selectedMonth"
                  @input="selectMonth"
                  class="attendance-datepicker cursor-pointer"
                  type="month"
                >
                  <template v-slot:trigger>
                    <b-button
                      icon-left="calendar-today"
                      type="is-primary"
                      class="attendance-employee-calendar-btn"
                      icon-right="chevron-down"
                    >
                      {{ formatDate(toDate, 'MMMM YYYY') }}
                    </b-button>
                  </template>
                </b-datepicker>
              </div>

              <div class="chevron-container is-flex is-align-items-center">
                <div class="is-flex-shrink-1">
                  <b-icon
                    icon="chevron-left"
                    @click.native="decreaseMonth"
                    class="cursor-pointer"
                  />
                </div>

                <div class="is-flex-shrink-1">
                  <b-icon
                    icon="chevron-right"
                    @click.native="increaseMonth"
                    class="cursor-pointer"
                  />
                </div>
              </div>

              <div v-if="enableExport">
                <b-dropdown aria-role="list" class="dropdown-generate-report">
                  <template #trigger>
                    <b-button
                      icon-left="file-document-multiple"
                      label="Export"
                      icon-right="chevron-down"
                      type="is-primary"
                      class="export-btn"
                    />
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="generateAttendanceReport()"
                  >
                    Export Employee
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="generateWFOReport()"
                  >
                    Export WFO Report
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="generateLateReport()"
                  >
                    Export Late Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>

          <div class="column is-6 p-0">
            <div
              class="columns is-multiline is-flex is-justify-content-flex-end"
            >
              <div class="column is-2-desktop is-12-tablet p-0 m-0">
                <span class="has-text-right-desktop">Working Hour</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0">
                <div class="legend is-early is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">&lt;8h</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend is-normal is-inline-block mr-2"></div>
                <span>8h</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend overtime is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">Overtime</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div class="legend time-off is-inline-block mr-2"></div>
                <span class="has-text-right-desktop">Leave</span>
              </div>
              <div class="column is-2-desktop is-12-tablet p-0 m-0 is-inline">
                <div
                  class="legend-timeoff time-off-sick is-inline-block mr-2"
                ></div>
                <span class="has-text-right-desktop">Sakit</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column p-0 mt-2 mb-5">
          <b-tag type="is-info is-light" class="p-5" size="is-medium">
            <b-icon
              icon="information"
              size="is-small"
              type="is-info"
              class="mr-2"
            />
            Kalendar mengacu pada tanggal cut off. Cut off yang digunakan adalah
            tanggal {{ cutoffSetting }}
          </b-tag>
        </div>

        <div id="table-request-employment" class="table-employee-attendance">
          <b-table
            :data="monthlyAttendance"
            :loading="isLoadingData"
            :bordered="false"
            :sticky-header="stickyHeaders"
          >
            <b-table-column
              field="first_name"
              label="Employees"
              v-slot="props"
              :centered="false"
            >
              <div class="columns is-multiline">
                <div
                  class="column is-1-desktop is-1-tablet p-0 m-0 td-profile-picture-container"
                >
                  <img
                    :src="
                      determineProfilePicture(
                        props.row.profilePicture,
                        props.row.gender
                      )
                    "
                  />
                </div>
                <div class="column pt-0 pb-0 is-flex is-align-items-center">
                  <div>
                    <p class="td-name">
                      {{ props.row.name }}
                    </p>
                    <p class="td-position">
                      {{ props.row.positionName }}
                    </p>
                  </div>
                </div>
              </div>
            </b-table-column>

            <b-table-column
              :label="formatDate(item, 'DD')"
              v-for="item in momentDateRange"
              :key="formatDate(item)"
              v-slot="props"
            >
              <div v-if="getLogByStartDate(formatDate(item), props.row.data)">
                <b-tooltip
                  class="tooltip-padding"
                  position="is-left"
                  multilined
                  size="is-small"
                  type="is-white"
                  @click.native="
                    getLogByStartDate(formatDate(item), props.row.data)
                      .isTimeOff
                      ? null
                      : selectAttendance(
                          getLogByStartDate(formatDate(item), props.row.data),
                          props.row
                        )
                  "
                >
                  <div
                    :class="
                      getClassCircleMarkWithColor(
                        formatDate(item),
                        props.row.data
                      )
                    "
                  ></div>

                  <template v-slot:content>
                    <div
                      class="columns is-multiline has-text-left tooltip-content-column"
                    >
                      <div class="column is-12">
                        <p>Total working Hour</p>
                        <p class="has-text-weight-bold">
                          {{
                            formatMinute(
                              convertDurationToMinute(
                                getLogByStartDate(
                                  formatDate(item),
                                  props.row.data
                                ).durationPerDay
                              )
                            )
                          }}
                        </p>
                      </div>
                      <div class="column is-5">
                        <p>Clock In</p>
                        <p class="has-text-weight-bold">
                          {{
                            getLogByStartDate(formatDate(item), props.row.data)
                              .isTimeOff
                              ? 'Time Off'
                              : formatTime(
                                  getLogByStartDate(
                                    formatDate(item),
                                    props.row.data
                                  ).firstCheckIn,
                                  'HH:mm'
                                )
                          }}
                        </p>
                      </div>
                      <div class="ml-2 column is-5">
                        <p>Clock Out</p>
                        <p
                          class="has-text-weight-bold"
                          v-if="
                            getLogByStartDate(formatDate(item), props.row.data)
                              .lastCheckOut
                          "
                        >
                          {{
                            getLogByStartDate(formatDate(item), props.row.data)
                              .isTimeOff
                              ? 'Time Off'
                              : formatTime(
                                  getLogByStartDate(
                                    formatDate(item),
                                    props.row.data
                                  ).lastCheckOut,
                                  'HH:mm'
                                )
                          }}
                        </p>
                        <p class="has-text-weight-bold" v-else>On-going</p>
                      </div>
                      <div
                        v-if="
                          getLogByStartDate(formatDate(item), props.row.data)
                            .isOvertime
                        "
                        class="column is-12"
                      >
                        <p>Request Overtime</p>
                        <p class="has-text-danger has-text-weight-bold">
                          {{
                            formatMinute(
                              getLogByStartDate(
                                formatDate(item),
                                props.row.data
                              ).overtime.hours * 60
                            )
                          }}
                          <b-tag
                            class="has-text-white has-text-weight-normal ml-3"
                            type="is-success"
                          >
                            Approved
                          </b-tag>
                        </p>
                      </div>
                      <div class="column is-12">
                        <p>Working Type</p>
                        <p class="has-text-weight-bold">
                          {{
                            getLogByStartDate(formatDate(item), props.row.data)
                              .isRemote
                              ? 'WFH'
                              : 'WFO'
                          }}
                        </p>
                      </div>
                    </div>
                  </template>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>

    <!--    <template #page-sidebar>-->
    <!--      <b-sidebar-->
    <!--        right-->
    <!--        v-model="isSideBarAttendanceOpen"-->
    <!--        fullheight-->
    <!--        id="attendance-sidebar"-->
    <!--        :can-cancel="['escape']"-->
    <!--      >-->
    <!--        <ContentSideBarEmployeeAttendance-->
    <!--          v-model="selectedAttendance"-->
    <!--          @close-sidebar="closeSidebar"-->
    <!--        />-->
    <!--      </b-sidebar>-->
    <!--    </template>-->
  </r-page>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { showToast } from '../../services/util'
import axios from 'axios'
import ActivityDetail from './ActivityDetail'

export default {
  components: {
    ActivityDetail,
  },
  props: {
    isLoadAllEmployments: Boolean,
    enableExport: Boolean,
  },
  data() {
    return {
      stickyHeaders: true,

      momentDateRange: [],
      fromDate: null,
      toDate: null,
      monthlyAttendance: [],
      selectedMonth: [],
      isLoadingData: false,
      selectedAttendance: null,
      isComponentModalActive: false,
      selectedUserId: null,
      selectedUserName: null,

      cutoffSetting: null,
    }
  },
  methods: {
    ...mapActions({
      actionLoadCurrentUser: 'user/loadUserFromToken',
      actionFetchMonthlyAttendance: 'attendance/fetchMonthlyAttendance',
    }),

    formatTime(str, format) {
      return moment(moment.utc(str).toDate()).format(format)
    },

    selectAttendance(attendance, row) {
      this.selectedAttendance = attendance
      this.selectedUserName = row.name
      this.selectedUserId = row.id

      this.isComponentModalActive = true
    },

    closeSidebar() {
      this.isComponentModalActive = false
      this.selectedAttendance = null
    },

    async selectMonth(val) {
      this.determineDateRange(val)
      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },
    formatMinute(durationInMinute, hourFormat = 'H', minuteFormat = 'M') {
      let hourF = hourFormat
      let minuteF = minuteFormat

      if (durationInMinute == null) {
        return null
      }
      if (durationInMinute < 60) {
        return durationInMinute.toString() + minuteF
      }
      //*  count hour
      let hour = Math.floor(durationInMinute / 60)
      //* count minute
      let minute = durationInMinute % 60
      return hour.toString() + hourF + ' ' + minute.toString() + minuteF
    },

    async increaseMonth() {
      // Increase Month from End Range Date
      this.determineDateRange(this.toDate.add('1', 'month'))
      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },
    async decreaseMonth() {
      // Decrease Month from End Range Date
      this.determineDateRange(this.toDate.subtract('1', 'month'))
      this.selectedMonth = [new Date(this.fromDate), new Date(this.toDate)]

      this.momentDateRange = []
      this.momentDateRange = this.generateMomentDateRange(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
      await this.fetchMonthlyAttendance(
        this.fromDate.format('YYYY-MM-DD'),
        this.toDate.format('YYYY-MM-DD')
      )
    },

    getClassCircleMarkWithColor(date, attendanceData) {
      let baseClass = 'circle-mark'
      let baseClassSick = 'box-mark'

      let log = this.getLogByStartDate(date, attendanceData)

      let classname = this.determineClassDuration(
        log.durationPerDay,
        log.isTimeOff,
        log.isOvertime,
        log.type
      )
      if (log.type === 'Sakit') {
        return baseClassSick + ' ' + classname
      }

      return baseClass + ' ' + classname
    },

    // parameter date format : 'YYYY-MM-DD'
    getLogByStartDate(date, attendanceData) {
      // Logic will be executed if the day is weekday
      if (!this.isWeekend(date)) {
        let filtered = attendanceData.filter((e) => e.date === date)
        let filteredTemp = filtered.filter((e) => e.is_time_off === false)

        if (filteredTemp.length > 0) {
          return filteredTemp[0]
        }
        if (filtered.length > 0) {
          return filtered[0]
        }
      }
      return null
    },

    convertDurationToMinute(durationPerDay) {
      let split = durationPerDay.split(':') // split it at the colons

      // Hours are worth 60 minutes.
      return +split[0] * 60 + +split[1]
    },

    // return class(string) that affect background color of circle mark
    //durationPerDay : hh:mm:ss format
    // isTimeOff : boolean
    determineClassDuration(durationPerDay, isTimeOff, isOvertime, type) {
      if (type === 'Sakit') return 'time-off-sick'
      if (isTimeOff) return 'not-active'

      if (isOvertime) return 'overtime'

      // convert all to minute
      let totalMinute = this.convertDurationToMinute(durationPerDay)

      let hoveredClass = 'circle-mark-cursor-pointer '
      // less than or equal 8 hour
      if (totalMinute < 8 * 60) {
        return hoveredClass + 'is-early'
      }
      return hoveredClass + 'is-normal'
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      return moment(moment.utc(date).toDate()).format(format)
    },
    determineProfilePicture(profilePictureUrl, gender) {
      if (!profilePictureUrl) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return profilePictureUrl
    },
    generateMomentDateRange(fromDate, toDate, unit = 'days') {
      let startDate = moment(fromDate)
      let endDate = moment(toDate)
      let diff = endDate.diff(startDate, unit)
      let range = []
      for (let i = 0; i <= diff; i++) {
        range.push(moment(fromDate).add(i, unit))
      }
      return range
    },
    async fetchMonthlyAttendance(startDate, endDate) {
      this.isLoadingData = true
      try {
        this.monthlyAttendance = await this.actionFetchMonthlyAttendance({
          startDate,
          endDate,
          isLoadAllEmployments: this.isLoadAllEmployments,
        })
      } catch (err) {
        showToast('Failed to fetch data', 'is-danger', 'is-top')
        console.log(err)
      }
      this.isLoadingData = false
    },

    // To generate attendance report
    async generateAttendanceReport() {
      // Loading Modal
      this.$swal.fire({
        icon: 'info',
        html:
          '<div style="text-align: center;">' +
          '<h3>Generating Employee Report</h3>' +
          '<p>Please wait ...</p>' +
          '</div>',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
        showConfirmButton: false,
      })
      try {
        const month = moment(this.selectedMonth[0]).format('M')
        const year = moment(this.selectedMonth[0]).format('YYYY')

        let response = await axios.get(`/api/space-roketin/attendance/export`, {
          headers: {
            'Content-Disposition': 'attachment; filename=AttendanceReport.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob',
          params: {
            month,
            year,
          },
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'AttendanceReport.xlsx')
        document.body.appendChild(link)
        link.click()

        // Success Modal
        if (response.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'Download Success',
            text: 'Employee Report is successfully downloaded',
            timer: 4000,
            showConfirmButton: false,
          })
        }
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Failed to fetch data',
          text: err,
          customClass: {
            confirmButton: 'button is-grey',
          },
        })
      }
    },

    // To generate WFO report
    async generateWFOReport() {
      const start_date = moment(this.selectedMonth[0]).format('YYYY-MM-DD')
      const end_date = moment(this.selectedMonth[1]).format('YYYY-MM-DD')

      try {
        let response = await axios.get(`/api/space-roketin/wfo/export`, {
          headers: {
            'Content-Disposition': 'attachment; filename=WFOReport.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob',
          params: {
            start_date,
            end_date,
          },
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'WFOReport.xlsx')
        document.body.appendChild(link)
        link.click()

        showToast('Export success', 'is-success', 'is-top')
      } catch (err) {
        showToast('Failed to fetch data', 'is-danger', 'is-top')
      }
    },
    async generateLateReport() {
      this.$swal.fire({
        icon: 'info',
        html:
          '<div style="text-align: center;">' +
          '<h3>Generating Late Report</h3>' +
          '<p>Please wait ...</p>' +
          '</div>',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
        showConfirmButton: false,
      })
      try {
        let month = moment(this.selectedMonth[0]).format('M')
        let year = moment(this.selectedMonth[0]).format('YYYY')
        let monthName = moment(this.selectedMonth[0]).format('MMMM')

        let response = await axios.get(
          `/api/space-roketin/attendance/export/late-report`,
          {
            headers: {
              'Content-Disposition': `attachment; filename=LateReport.xlsx`,
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
            params: {
              month,
              year,
            },
          }
        )
        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${monthName}-${year}-late-report.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Success Modal
        if (response.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'Download Success',
            text: 'Late Report is successfully downloaded',
            timer: 4000,
            showConfirmButton: false,
          })
        }
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Failed to fetch data',
          text: err,
          customClass: {
            confirmButton: 'button is-grey',
          },
        })
      }
    },
    async fetchCutoffSetting() {
      try {
        let response = await this.actionLoadCurrentUser()
        this.cutoffSetting = response.data.cutoffDay
        this.determineDateRange(moment())
      } catch (err) {
        console.log(err)
      }
    },
    determineDateRange(currentDate) {
      currentDate = moment(currentDate)
      let month = currentDate.format('MM')
      let year = currentDate.format('YYYY')

      let endMonth = currentDate.endOf('month').format('DD')
      // if cutoff setting exceeds the last day of the month
      let diffDay = 0
      if (endMonth < this.cutoffSetting) {
        diffDay = this.cutoffSetting - endMonth
        this.toDate = moment(year + '-' + month + '-' + endMonth)
      } else {
        this.toDate = moment(year + '-' + month + '-' + this.cutoffSetting)
      }
      this.fromDate = moment(this.toDate)
        .subtract(1, 'month')
        .add(1 + diffDay, 'day')
    },
    isWeekend(value) {
      // created moment date from parameter given, then convert it to js date object and get the number of the day
      // *Notes: This method is lighter than taking the name of the day
      const dayByNumber = moment(value).toDate().getDay()
      return dayByNumber === 6 || dayByNumber === 0
    },
  },
  async mounted() {
    await this.fetchCutoffSetting()
    // generate date range for table purpose
    this.momentDateRange = this.generateMomentDateRange(
      this.fromDate.format('YYYY-MM-DD'),
      this.toDate.format('YYYY-MM-DD')
    )
    // load data
    this.fetchMonthlyAttendance(
      this.fromDate.format('YYYY-MM-DD'),
      this.toDate.format('YYYY-MM-DD')
    )
    // create first and last date of the month
    this.selectedMonth = [
      new Date(this.fromDate.format('YYYY-MM-DD')),
      new Date(this.toDate.format('YYYY-MM-DD')),
    ]
  },
}
</script>
